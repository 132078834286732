import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi } from '../ContractABI'; // Adjust the path as needed
import '../styles/ConnectButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import logoImage from "../image/AIBOT_logo.png"; // Update the path to your actual logo image


const contractAddress = '0x3F145B3cf015D2f18D0e38A1cCf023f7Ee8cbAb5';

// Web3Modal configuration
const projectId = '5c9b9db811451dc1508853b7a43a1695'; // Replace with your WalletConnect project ID
const infuraUrl = 'https://goerli.infura.io/v3/15083069ebbe40b6947abaabe8a27821'; // Infura URL for Goerli
const bscMainnet = {
  chainId: "0x38", // Hexadecimal for 56 (BSC Mainnet)
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
};

const metadata = {
  name: "BULL AI X",
  description: "BUX",
  url: "https://bullaix.com",
  icons: [
    logoImage,
  ],
};

const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId
  });


  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);

  

  const switchToBsc = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: bscMainnet.chainId }], // Updated to BSC Mainnet chainId
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: bscMainnet.chainId,
                rpcUrl: bscMainnet.rpcUrl,
                // Add any other relevant parameters here
              },
            ],
          });
        } catch (addError) {
          console.error('Error adding BSC Mainnet:', addError);
        }
      }
    }
  };

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress('');
          
          }
        });
      }
    };
  
    subscribeToAccountChanges();
  
    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);
  
  useEffect(() => {
    const fetchWalletDetails = async () => {
      if (signer) {
        try {
          const network = await signer.getChainId();
          if (network !== parseInt(bscMainnet.chainId, 16)) {
            await switchToBsc(); // This function should switch the network to BSC Mainnet
            return;
          }
          const address = await signer.getAddress();
          const balance = await signer.getBalance();
          setCurrentUserAddress(address);
          const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
          setWalletBalance(etherBalance);
        } catch (error) {
          console.error('Error fetching wallet details:', error);
        }
      }
    };
  
    fetchWalletDetails();
  }, [signer]);  
  
  const isWalletConnected = () => {
    return currentUserAddress !== '';
  };

  

  return (
    
    <div>
    {isWalletConnected() ? (
      <div className="dropdown">
        <button className="dropbtn" onClick={() => open()}>
          <FontAwesomeIcon icon={faWallet} />
            {` ${currentUserAddress.substring(0, 3)}...${currentUserAddress.substring(currentUserAddress.length - 4)}`}
        </button>
        <div className="dropdown-content">
        <span className="wallet-address-display">
            Wallet connected: {`${currentUserAddress.substring(0, 6)}...${currentUserAddress.substring(currentUserAddress.length - 4)}`}
          </span>
          <p>Balance: {walletBalance} BNB</p>
        </div>
      </div>
    ) : (
      <button className="connect-wallet-button" onClick={() => open()}>
        Connect Wallet
      </button>
    )}
  </div>
  
  );
}


