// src/components/Header.js

import React from 'react';

import './Header.css'; // We will create this CSS file next
import logoImage from '../image/AIBOT_logo.png';
import '../App.css';
import { Link } from 'react-router-dom';
import LotteryInterface from './airdropInterface';


function Header() {
  return (
    <header className="header">
      <div className="logo">
      <img src={logoImage} alt="Logo" />
      </div>
      <nav className="navigation">
      <ul>
    <li><a href="#introduction">Introduction</a></li>
    <li><a href="#partners">Partners</a></li>
    <li><a href="#Tokenomics">Tokenomics</a></li>
    <li><a href="#how-to-buy">How to buy</a></li>
    <li><a href="#lottery">Lottery</a></li> 
    <li><a href="#roadmap">Roadmap</a></li>
    <li><a href="#faq">FAQ</a></li>
    <li><a href="https://tokenexplorer.flokiaibot.com/">Staking</a></li>

</ul>
</nav>
<div className="header-buttons">
  {/* Agrega aquí cualquier botón o enlaces adicionales */}

  <a href="https://tokenexplorer.flokiaibot.com/">
    <button >Launch Token Explorer</button>
</a>
  <a href="https://pancakeswap.finance/swap?outputCurrency=0xaE4c637fb9cb5C151549768a787CCa54c044BdcA">
    <button >Buy Now</button>
</a>
<a href="https://www.dexview.com/bsc/0xaE4c637fb9cb5C151549768a787CCa54c044BdcA">

            <button >Chart</button>
            </a>


</div>

    </header>
  );
}

export default Header;
