import React from 'react';
import './lottery.css'; // Make sure to create this CSS file
import tropy from "../assets/tropy.png";
import lottery2 from "../assets/lottery2.png";


    function Lottery() {
        return (
          <div className="lottery__app">
            <div className="lottery__content">
              <h1 className="lottery__title">PLAY & GAIN REWARDS</h1>   
              <img src={tropy} alt="Exchange Icon"  className="lottery__image"/>
              <p className="lottery__subtitle">Fun & Fair Rewards For Everyone</p>
              <a href="https://dapp.flokiaibot.com/lottery" target="_blank" rel="noopener noreferrer">
              <button className="lottery__button">START PLAYING!</button>
             </a>
             </div>
            
            <div className="lottery__details">
              <h2 className="lottery__details-title">Lottery Details</h2>
        <p className="lottery__text">Cost per ticket: $3 in BNB</p>
        <p className="lottery__text">Tickets available: 25</p>
        <p className="lottery__text">Once the last ticket is purchased, our smart contract will randomly select 3 winners.</p>
        <ul className="lottery__list">
          <li>First place will win 40% of the prize pool.</li>
          <li>Second place will win 20% of the prize pool.</li>
          <li>Third place will win 10% of the prize pool.</li>
        </ul>
        <p className="lottery__text">All prizes will be distributed in FAIB2 tokens.</p>
        <p className="lottery__text">20% of the remaining balance will be used to burn FAIB2 tokens.</p>
        <p className="lottery__text">The last 10% will be allocated for development and marketing.</p>
      </div>
      <img src={lottery2} alt="Description of Image 2" className="lottery__details-image"/>

    </div>
  );
}

export default Lottery;
