// src/components/MainContent.js

import React, { useState } from 'react';
import './MainContent.css'; // Make sure to create this CSS file
import './copytoclipboard.css'; // Make sure to create this CSS file
import './airdrop.css'; // Make sure to create this CSS file
import './lottery.css'; // Make sure to create this CSS file

import Header from '../components/Header'; // Import the Header component
import MainContent1 from '../components/MainContent'; // Import the Header component

import './App.css'


function MainContent() {
// Inside your MainContent component

  return (

    <div className="App">
    <header className="App-header">
      <Header /> {/* Include the Header component */}
      </header>
    
      <main className="App-main"> 
      <MainContent1 /> {/* Include the MainContent component */}
      </main>
      
      <footer className="App-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h4>About FAIB2</h4>
            <p>Learn more about FAIB2 its vision, and how it's changing the crypto landscape.</p>
          </div>
          <div className="footer-section">
            <h4>Resources</h4>
            <ul>
              <li><a href="#!">Whitepaper</a></li>
              <li><a href="#!">Support</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact Us</h4>
            <p>Have questions? Reach out to our support team for assistance.</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 FAIB2. All rights reserved.</p>
        </div>
      </footer>

    </div>


  );
}

export default MainContent;


