// src/pages/LotteryPage.js
import React from 'react';
import AirdropInterface from '../components/airdropInterface';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import '../styles/Dashboard.css';
import logoImage from '../image/AIBOT_logo.png';
import ConnectButton from '../components/ConnectButton'; // Adjust the path as necessary
import '../components/Header.css'; // We will create this CSS file next


const Airdrop = () => {
  const toggleMenu = () => {
    const leftMenu = document.querySelector('.header-section.left');
    const rightMenu = document.querySelector('.header-section.right');
    leftMenu.classList.toggle('open');
    rightMenu.classList.toggle('open');
  };
  return (
    <div>
      <header className="header">
      <div className="logo">
      <img src={logoImage} alt="Logo" />
      <nav className="navigation">
      <ul>
    
    <li><a href="/">Home</a></li>
    <li><a href="/Airdrop">Airdrop</a></li>

   

</ul>
</nav>
      </div>

<div className="header-buttons">
  {/* Agrega aquí cualquier botón o enlaces adicionales */}
  <button>Comprar Ahora</button>
  <button>Gráfico en Vivo</button>
  <ConnectButton /> {/* Add the ConnectButton here */}

</div>

    </header>
     
      <AirdropInterface/>
    </div>
  );
};

export default Airdrop;
