// src/components/MainContent.js

import React, { useState, useEffect } from 'react';
import "./MainContent.css"; // Make sure to create this CSS file
import "./copytoclipboard.css"; // Make sure to create this CSS file
import "./airdrop.css"; // Make sure to create this CSS file
import "./about.css"; // Make sure to create this CSS file
import "./tokeninfo.css"; // Make sure to create this CSS file
import { PieChart } from 'react-minimal-pie-chart';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Slider from 'react-slick';


import logoImage from "../image/AIBOT_logo.png"; // Update the path to your actual logo image
import dexview_logo from "../image/dexview_logo.png";
import dextools from "../image/dextools.png";
import poocoin from "../image/poocoin.png";
import pancakeswap from "../image/pancakeswap.png";
import pancakeswap2 from "../image/pancakeswap2.png";
import bnb_logo from "../image/bnb.png";
import metamask_logo from "../image/metamask.png";
import airdrop from "../image/airdropImg.webp";

import Lottery from '../components/lottery'; // Import the Header component


function MainContent() {
  // Inside your MainContent component
  const [activeTab, setActiveTab] = useState('tab1');
  const [isHovering, setIsHovering] = useState(false);
  const [activeChart, setActiveChart] = useState('funding');


  useEffect(() => {
    const tabs = ['tab1', 'tab2']; // List of tab IDs
    let currentIndex = 0;

    const switchTab = () => {
      if (!isHovering) {
        currentIndex = (currentIndex + 1) % tabs.length;
        setActiveTab(tabs[currentIndex]);
        updateTabDisplay(tabs[currentIndex]);
      }
    };

    let tabSwitcherId = setInterval(switchTab, 5000); // Switch every 5 seconds

    const updateTabDisplay = (activeTabId) => {
      document.querySelectorAll('.tab-content').forEach(content => {
        content.style.display = content.id === activeTabId ? 'block' : 'none';
      });
      document.querySelectorAll('.tab-button').forEach(button => {
        button.classList.remove('active');
        if (button.dataset.tab === activeTabId) {
          button.classList.add('active');
        }
      });
    };

    // Attach event listeners for manual tab switching
    document.querySelectorAll('.tab-button').forEach(button => {
      button.addEventListener('click', (event) => {
        const tabId = event.target.dataset.tab;
        setActiveTab(tabId);
        updateTabDisplay(tabId);
        clearInterval(tabSwitcherId); // Clear and reset the interval
        tabSwitcherId = setInterval(switchTab, 5000);
      });
    });

    const container = document.querySelector('.token-info'); // Adjust selector as needed
    container.addEventListener('mouseenter', () => setIsHovering(true));
    container.addEventListener('mouseleave', () => setIsHovering(false));

    // Initial display update
    updateTabDisplay(activeTab);

    // Clear interval and event listeners on cleanup
    return () => {
      clearInterval(tabSwitcherId);
      document.querySelectorAll('.tab-button:hover').forEach(button => {
        button.removeEventListener('click', (event) => {
          const tabId = event.target.dataset.tab;
          setActiveTab(tabId);
          updateTabDisplay(tabId);
          clearInterval(tabSwitcherId);
          tabSwitcherId = setInterval(switchTab, 5000);
        });
      });
      container.removeEventListener('mouseenter', () => setIsHovering(true));
      container.removeEventListener('mouseleave', () => setIsHovering(false));
    };
  }, [isHovering, activeTab]);

  const toggleMenu = () => {
    const leftMenu = document.querySelector('.header-section.left');
    const rightMenu = document.querySelector('.header-section.right');
    leftMenu.classList.toggle('open');
    rightMenu.classList.toggle('open');
  };

  const copyToClipboard = () => {
    const copyText = document.getElementById("contractAddress");
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value).then(() => {
      // Alert the copied text
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  };



  const faqs = [
    {
      pregunta: "What is Floki AI BOT 2.0?",
      respuesta:
      "Floki AI BOT 2.0 is a new meme cryptocurrency token that operates on the BEP-20 Blockchain technology (Binance Smart Chain). It is designed to offer high-performance protocol with integral price stability features."    },
    {
      pregunta: "What is the total supply of Floki AI BOT 2.0?",
      respuesta:
      "The total supply of Floki AI BOT 2.0 is 1,000,000,000,000,000 tokens."
    },
    {
      pregunta: "How does the 6% transaction tax work?",
      respuesta:
      "The 6% transaction tax is split between a 1% burn tax, a 1% liquidity tax, 1% reflexions and a 3% Marketing. The burn tax reduces the total supply of tokens in circulation, while the liquidity tax adds to the liquidity pool to prevent major price fluctuations."
    },
    {
      pregunta: "What makes Floki AI BOT 2.0 unique compared to other meme coins?",
      respuesta:
      "Floki AI BOT 2.0 aims to provide a more stable and sustainable investment opportunity compared to other meme coins. With its focus on price stability and community rewards, Floki AI BOT 2.0 is designed to reduce risk for investors and promote long-term growth."
    },
    {
      pregunta: "Where can I buy Floki AI BOT 2.0?",
      respuesta:
      "Floki AI BOT 2.0 can be purchased on various decentralized exchanges (DEX) that support BEP-20 tokens, such as PancakeSwap."
    },
    
  ];

  function scrollLeft() {
    document.querySelector('.roadmap-carousel').scrollBy({ left: -300, behavior: 'smooth' });
}

function scrollRight() {
    document.querySelector('.roadmap-carousel').scrollBy({ left: 300, behavior: 'smooth' });
}

  const tabCarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  
  const [activeIndex, setActiveIndex] = useState(null);
  const [tooltip, setTooltip] = useState({ index: null, x: 0, y: 0 });

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="main-content">
      <div className="part1" id="introduction">
        <div className="content-left">
          <h1>FLOKI AI BOT 2.O </h1>
          <p>
          Floki AI BOT 2.0 (FAIB2) is a cryptocurrency token that operates on the BEP-20 Blockchain technology (Binance Smart Chain). Our core team brings a wealth of experience and knowledge to this exciting new project, which aims to provide a secure and efficient platform for trading digital assets.

We believe in the power of community, and our goal is to build a strong and supportive network of investors and enthusiasts around the world. With our unique features and innovative technology, we’re confident that Floki AI BOT 2.0 will become a top player in the world of decentralized finance.

While we don’t make any specific market predictions, we’re excited about the potential of Floki AI BOT 2.0 to make a significant impact in the crypto space. Join us today and become part of the meme revolution with Floki AI BOT 2.0!
          </p>
          <div className="buttons">
          <a href="https://pancakeswap.finance/swap?outputCurrency=0xaE4c637fb9cb5C151549768a787CCa54c044BdcA">
    <button className="btn buy-now">Buy Now</button>
</a>
<a href="https://www.dexview.com/bsc/0xaE4c637fb9cb5C151549768a787CCa54c044BdcA">

            <button className="btn live-chart">Chart</button>
            </a>

            <a href="https://t.me/FlokiAIBot503" className="hero-social-button">
              <i className="fab fa-telegram-plane" aria-hidden="true"></i>
            </a>
            <a href="https://twitter.com/FAIB2_0" className="hero-social-button">
              <i className="fab fa-twitter" aria-hidden="true"></i>
            </a>
            <a
              href="https://bscscan.com/address/0xaE4c637fb9cb5C151549768a787CCa54c044BdcA"
              target="_blank"
              rel="noopener noreferrer"
              className="bsc-link-button"
            >
              <i className="fas fa-globe"></i>
            </a>
          </div>

          <div className="contract-address-container">
            <input
              type="text"
              value="0xaE4c637fb9cb5C151549768a787CCa54c044BdcA"
              id="contractAddress"
              readOnly
            />
            <button onClick={copyToClipboard}>
              <span>Contract</span>
              <i className="far fa-clipboard"></i>
            </button>
          </div>
        </div>
        <div className="content-right">
          <img src={logoImage} alt="Solmoon Logo" className="main-logo" />
        </div>
      </div>

      <div className="partners-container" id="partners">
        <div className="partners">
          <img src={dexview_logo} alt="dexview 1" />
          <img src={dextools} alt="dextools 1" />
          <img src={poocoin} alt="dextools 1" />
          <img src={pancakeswap} alt="dextools 1" />
          <img src={dexview_logo} alt="dexview 1" />
          <img src={dextools} alt="dextools 1" />
          <img src={poocoin} alt="dextools 1" />
          <img src={pancakeswap} alt="dextools 1" />
        </div>
      </div>

      <div className="container-about">
            <div className="about">  
            <h2>About Us!</h2>
  <h3>The Evolution of Floki AI BOT 2.0 (FAIB2)</h3>
  <p>
  Welcome to Floki AI BOT 2.0 – the forefront of cryptocurrency innovation. Operating on the robust BEP-20 Blockchain technology (Binance Smart Chain), FAIB2 is more than just a cryptocurrency token; it's a beacon of progress in the digital asset trading world. Our core team, composed of seasoned experts in blockchain technology, financial markets, and AI development, is thrilled to bring this revolutionary project to life.

</p>
<h3>Our Technology: A Leap Forward in Decentralized Finance</h3>
<p>
At the heart of Floki AI BOT 2.0 is our cutting-edge technology designed to redefine the standards of security and efficiency in cryptocurrency trading. Our platform integrates advanced AI algorithms with the decentralization benefits of blockchain, offering predictive analytics, automated trading strategies, and real-time market insights. This potent combination ensures that our users not only navigate the crypto space more effectively but also have a competitive edge.
</p>
<h3>Our Vision: Empowering the Community</h3>
<p>
We envision a world where trading in digital assets is accessible, transparent, and community-driven. Floki AI BOT 2.0 is committed to democratizing finance, breaking down the barriers that have traditionally made investment opportunities exclusive. By fostering a supportive and inclusive community, we aim to educate, engage, and empower individuals across the globe, regardless of their experience level in the crypto market.
</p>
<h3>Building a Strong Community: Your Voice Matters</h3>
<p>
Our belief in the power of community is unwavering. We are dedicated to building a strong, vibrant network of investors, enthusiasts, and visionaries who are as passionate about the potential of decentralized finance as we are. Through interactive platforms, regular updates, and open dialogue, we ensure that our community is always at the forefront of our decisions and innovations.
</p>
<h3>Innovative Features: Setting New Benchmarks</h3>
<p>
Floki AI BOT 2.0 introduces a suite of unique features that set it apart in the crypto space. From AI-driven market analysis to secure, fast transactions and an intuitive user interface, every aspect of FAIB2 is designed with the user experience in mind. Our commitment to innovation is evident in the continuous updates and enhancements to our platform, ensuring that we stay ahead of market trends and user needs.
</p>
<h3>Join the Meme Revolution</h3>

<p>
As we chart a new course in decentralized finance, we invite you to join us on this exciting journey. Floki AI BOT 2.0 is not just a token; it's a movement towards a more open, efficient, and inclusive financial ecosystem. Be part of the meme revolution that is set to change the face of the cryptocurrency world.
</p>
<p>

Together, we are not just creating a cryptocurrency token; we are building a legacy. Welcome to the future. Welcome to Floki AI BOT 2.0.
  </p>
</div>
<div className="image-about-container">
                {/* Image goes here */}
    <img className= "about-image" src={logoImage} alt="logo" />
     </div>

        
</div>

<section className="pie-charts-container">
       


          <div id="Tokenomics" className="token-info" >
          <div className="pie-chart-header">Token Information</div>

<Slider {...tabCarouselSettings}>
  {/* Slide for 'Token' tab data */}
  <div className="tab-carousel-slide">
    <div className="inline-content-header"><h3>Token</h3></div>

    <div className="inline-content"><h3>Name:</h3><p>Floki AI BOT 2.0</p></div>
    <div className="inline-content"><h3>Network:</h3><p>BEP-20</p></div>
    <div className="inline-content"><h3>Symbol:</h3><p>FAIB2</p></div>
    <div className="inline-content"><h3>Supply:</h3><p>1,000,000,000,000,000</p></div>
    <div className="inline-content"><h3>Decimals:</h3><p>18</p></div>
    <div className="inline-content"><h3>Contract:</h3><p>0xaE4c637fb9cb5C151549768a787CCa54c044BdcA</p></div>
  </div>

  {/* Slide for 'Tax' tab data */}
  <div className="tab-carousel-slide">
    <div className="inline-content-header"><h3>Tax</h3></div>
    <div className="inline-content"><h3>Buy Tax:</h3><p>6%</p></div>
    <div className="inline-content"><h3>Sell Tax:</h3><p>6%</p></div>
    <div className="inline-content"><h3>Transfer Tax:</h3><p>0%</p></div>
    <div className="inline-content-header"><h3>Tax Allocation</h3></div>
    <div className="inline-content"><h3>Marketing:</h3><p>3%</p></div>
    <div className="inline-content"><h3>Reflexions:</h3><p>1%</p></div>
    <div className="inline-content"><h3>Burn:</h3><p>1%</p></div>
    <div className="inline-content"><h3>LP:</h3><p>1%</p></div>
  </div>
  </Slider>

  </div>

        </section>

      
      <div
        id="how-to-buy"
        className="how-to-buy-section"
        style={{ background: "dark-gradient", color: "white" }}
      >
        <h1>How to Buy $FAIB2</h1>
<p>
  If you don't know how to join $FAIB2, don't worry, follow the
  instructions below and you'll easily join $FAIB2.
</p>
<div className="steps-container">
  <div className="step">
    <img
      src={bnb_logo}
      alt="Exchange Icon"
      className="responsive-image"
    />
    <h2>Buy BNB</h2>
    <p>Buy BNB or transfer it to your MetaMask account.</p>
  </div>
</div>
<div className="steps-container">
  <div className="step">
    <img
      src={metamask_logo}
      alt="Wallet Icon"
      className="responsive-image"
    />
    <h2>Install MetaMask</h2>
    <p>Open your browser and visit metamask.io</p>

          </div>
          <div className="step">
            <img
              src={pancakeswap2}
              alt="Icono de Intercambio"
              className="responsive-image"
            />
            <h2>Trade on PancakeSwap</h2>
<p>Visit PancakeSwap and click on connect wallet.</p>

          </div>
        </div>
        <a href="https://pancakeswap.finance/swap?outputCurrency=0xaE4c637fb9cb5C151549768a787CCa54c044BdcA">
        <button className="buy-button">Buy Now</button>
        </a>

      </div>

      <section> 
      <main className="lottery_main" id='lottery'> 
      <Lottery /> {/* Include the MainContent component */}
      </main>
      </section>

      <div className="roadmap-container" id="roadmap">
  <h2 className="roadmap-title">Roadmap</h2>
  <div className="roadmap">
    <div className="roadmap-item">
      <h3>Phase 1</h3>
      <p>
        <span className="icon checkmark">✔️</span> 1.1 - Telegram Group Opening
      </p>
      <p>
        <span className="icon checkmark">✔️</span> 1.2 - Website Development
      </p>
      <p>
        <span className="icon checkmark">✔️</span> 1.3 - Token Creation
      </p>
      <p>
        <span className="icon xmark">✔️</span> 1.4 - Token Launch
      </p>
      <p>
        <span className="icon xmark">✔️</span> 1.5 - Marketing Initiatives
      </p>
      <p>
        <span className="icon xmark">✔️</span> 1.6 - Liquidity Locking
      </p>
      <p>
        <span className="icon xmark">✔️</span> 1.7 - Contract Renunciation
      </p>
     
    </div>
    <div className="roadmap-item">
      <h3>Phase 2</h3>
      <p>
        <span className="icon xmark">✔️</span> 2.1 - DAPP Launch
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.2 - Referral Contest
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.3 - Strategic Partnerships
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.4 - Social Network Creation
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.5 - Marketing on Coinsniper, Coinscope, Coinhunters & Coinvote
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.6 - NFT Collection Design
      </p>
      <p>
        <span className="icon xmark">⏳</span> 2.7 - NFT Minting Integration
      </p>
    </div>
    <div className="roadmap-item">
      <h3>Phase 3</h3>
      <p>
        <span className="icon xmark">⏳</span> 3.1 - 1000 Holdings: Trending Launch on iDextool, Ave Trending, etc.
      </p>
      <p>
        <span className="icon xmark">⏳</span> 3.2 - 2000 Holdings: Listing on CG/CMC
      </p>
      <p>
        <span className="icon xmark">⏳</span> 3.3 - Aggressive Marketing on TG and Twitter
      </p>
      <p>
        <span className="icon xmark">⏳</span> 3.4 - Community Expansion and Online Events
      </p>
      <p>
        <span className="icon xmark">⏳</span> 3.5 - Purchase Contest (prizes of 23 BNB)
      </p>
      <p>
        <span className="icon xmark">⏳</span> 3.6 - Raffle for all holders of $1000 upon reaching a Market Cap of $1,000,000
      </p>
    </div>
  </div>
</div>


      <div className="faq-container" id="faq">
        <h2 className="faq-title">FAQ</h2>
        <div className="faq">
          {faqs.map((faq, index) => (
            <div
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleFAQ(index)}
              key={index}
            >
              <h3>{faq.pregunta}</h3>
              <p>{faq.respuesta}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainContent;
