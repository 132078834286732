import React, { useState, useEffect, createContext } from "react";

import { ethers } from "ethers";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { useWeb3Modal, useWeb3ModalSigner } from "@web3modal/ethers5/react";
import { abi } from "../ContractABI";
import "../styles/airdropInterface.css";
import "../styles/Lottery.css";
import "../styles/airdrop.css";
import "../styles/tabs.css";
import logoImage from "../image/AIBOT_logo.png"; // Update the path to your actual logo image


const contractAddress = "0x3F145B3cf015D2f18D0e38A1cCf023f7Ee8cbAb5";

// Web3Modal configuration
const projectId = "5c9b9db811451dc1508853b7a43a1695"; // Replace with your WalletConnect project ID

const bscMainnet = {
  chainId: "0x38", // Hexadecimal for 56 (BSC Mainnet)
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org/", // BSC Mainnet RPC URL
};

const metadata = {
  name: "BULL AI X",
  description: "BUX",
  url: "https://bullaix.com",
  icons: [
    logoImage,
  ],
};

const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId
  });

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [airdrops, setAirdrops] = useState([]);
  const [showTable, setShowTable] = useState(false); // Initial state is to show the table
  const [totalAmount, setTotalAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [airdropAmount, setAirdropAmount] = useState(0); // State to store airdrop amount
  const [nextAirdropTime, setNextAirdropTime] = useState(0);
  const [referralCode, setReferralCode] = useState(""); // State for storing the referral code
  const [myReferralCode, setMyReferralCode] = useState(""); // State to store the fetched referral code
  const [showNotification, setShowNotification] = useState(false);
  const [showReferralCode, setShowReferralCode] = useState(false);
  const [userReferralCode, setUserReferralCode] = useState("");
  const [isSettingReferralCode, setIsSettingReferralCode] = useState(false);
  const [referralRewards, setReferralRewards] = useState(0);
  const [referralCodeError, setReferralCodeError] = useState("");
  const [activeTab, setActiveTab] = useState("airdrops");
  const [referralClaimError, setReferralClaimError] = useState("");
  const [referralCodeSuccessMessage, setReferralCodeSuccessMessage] = useState("");
  const [tokenPrice, setTokenPrice] = useState(null);

  
  const fetchTokenPrice = async () => {
    const tokenAddress = "0xaC75851621553070e580c725abc3cb4CB2191CA9";
    const url = `https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/${tokenAddress}`;

    try {
        const response = await fetch(url, {
            headers: { 
                'accept': 'application/json;version=20230302'
            }
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        console.log("API Response:", data);

        // Correctly extracting the price from the response
        const price = data.data.attributes.token_prices[tokenAddress.toLowerCase()];
        setTokenPrice(price);
    } catch (error) {
        console.error("Failed to fetch token price:", error);
    }
};

useEffect(() => {
    fetchTokenPrice();
}, []);


const fetchAirdrops = async () => {
  try {
      const data = await contract.getAllAirdrops();
      let totalAmount = ethers.BigNumber.from("0");
      const formattedAirdrops = data.map((d) => {
          const amountInBigNumber = ethers.BigNumber.from(d.amount);
          totalAmount = totalAmount.add(amountInBigNumber);
          return {
              user: d.user,
              amount: ethers.utils.formatEther(amountInBigNumber),
              timestamp: new Date(d.timestamp * 1000),
          };
      });
      setAirdrops(formattedAirdrops);
      setTotalAmount(ethers.utils.formatEther(totalAmount));
  } catch (error) {
      console.error("Error fetching airdrops:", error);
  }
};


  // Correct: useEffect is at the top level of the component
  useEffect(() => {
    fetchAirdrops();
  }, []); // Empty dependency array to run only once
  // Rest of your application

  const fetchNextAirdropTime = async () => {
    if (signer) {
      try {
        const contractWithSigner = contract.connect(signer);
        const nextTime = await contractWithSigner.getNextAirdropAvailability();
        setNextAirdropTime(nextTime.toNumber());
      } catch (error) {
        console.error("Error fetching next airdrop time:", error);
      }
    }
  };

  useEffect(() => {
    fetchNextAirdropTime();
  }, [signer, currentUserAddress]); // Depend on signer and currentUserAddress

  const calculateTimeLeft = () => {
    if (!nextAirdropTime) {
      return { days: "d", hours: "h", minutes: "m", seconds: "s" };
    }

    const difference = nextAirdropTime * 1000 - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      // Countdown has ended
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const fetchMyReferralCode = async () => {
    if (!signer || !isWalletConnected) {
      console.error("Wallet not connected!");
      return;
    }

    try {
      const contractWithSigner = contract.connect(signer);
      const fetchedCode = await contractWithSigner.getMyReferralCode();
      setMyReferralCode(fetchedCode);
    } catch (error) {
      console.error("Error fetching referral code:", error);
    }
  };

  const fetchReferralRewards = async () => {
    if (signer && currentUserAddress) {
      try {
        const contractWithSigner = contract.connect(signer);
        const rewards = await contractWithSigner.referralRewards(
          currentUserAddress
        );
        setReferralRewards(ethers.utils.formatEther(rewards)); // Assuming rewards are in wei
      } catch (error) {
        console.error("Error fetching referral rewards:", error);
      }
    }
  };

  useEffect(() => {
    fetchReferralRewards();
  }, [signer, currentUserAddress]); // Re-fetch when signer or user address changes

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const displayTimer = () => {
    // If nextAirdropTime is not set or zero, show a default message
    if (!nextAirdropTime) {
      return <span>A la espera del primer Airdrop...</span>;
    }

    const { days, hours, minutes, seconds } = timeLeft;

    // Check if the countdown has reached zero
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      return <span>Airdrop disponible ahora!</span>;
    } else {
      return (
        <p>
          Próximo Airdrop en:
          <span>{days}d </span>
          <span>{hours}h </span>
          <span>{minutes}m </span>
          <span>{seconds}s</span>
        </p>
      );
    }
  };

  /*-----------------------------------------------------------------------------------------------------*/
  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        // Convert the balance to Ether (wei to Ether) and round it to 4 decimal places
        const etherBalance = parseFloat(
          ethers.utils.formatEther(balance)
        ).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
      }
    }
  };

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const newAddress = await signer.getAddress();
        if (newAddress !== currentUserAddress) {
          setCurrentUserAddress(newAddress);
          console.log("Current User Address:", newAddress); // Add this line for debugging
        }
      }
    };

    fetchCurrentUserAddress();
    fetchWalletBalance(); // Assuming this doesn't cause state updates leading to re-rendering.
  }, [signer]);

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on("accountsChanged", async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress("");
          }
        });
      }
    };

    subscribeToAccountChanges();

    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          subscribeToAccountChanges
        );
      }
    };
  }, []);

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setCurrentUserAddress(address);
        setIsWalletConnected(true); // Set isWalletConnected to true
        // ... rest of the existing code
      }
    };

    fetchCurrentUserAddress();
  }, [signer]);

  // Function to handle wallet connect
  const handleWalletConnect = async () => {
    await open();
    await checkAndSwitchNetwork();
    // ...rest of your connection logic
  };

  const desiredNetwork = {
    chainId: bscMainnet.chainId, // Hexadecimal chain ID for BSC Mainnet
    chainName: bscMainnet.name,
    rpcUrls: [bscMainnet.rpcUrl],
    blockExplorerUrls: [bscMainnet.explorerUrl],
  };

  const checkNetwork = async () => {
    if (signer) {
        try {
            const network = await signer.provider.getNetwork();
            setIsCorrectNetwork(
                network.chainId === parseInt(bscMainnet.chainId, 16)
            );
        } catch (error) {
            console.error("Error checking network:", error);
            setIsCorrectNetwork(false);
        }
    }
};


  useEffect(() => {
    checkNetwork();

    const handleNetworkChange = () => {
      checkNetwork();
    };

    // Subscribe to network change events
    // This part is specifically for MetaMask and similar wallets
    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleNetworkChange);

      return () => {
        window.ethereum.removeListener("chainChanged", handleNetworkChange);
      };
    }
    // Note: For wallets like WalletConnect, you'll need to handle this differently
  }, [signer]); // Add 'signer' as a dependency if it's defined in your component's state

  const checkAndSwitchNetwork = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        if (network.chainId !== parseInt(desiredNetwork.chainId, 16)) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: desiredNetwork.chainId }],
            });

            // Refresh the page after successfully switching the network
            window.location.reload();
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: [desiredNetwork],
                });

                // Consider refreshing the page after adding the new network, if necessary
                // window.location.reload();
              } catch (addError) {
                // Handle errors when adding a new network
                console.error(addError);
              }
            } else {
              console.error(switchError);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // Handle the case where the user doesn't have MetaMask installed
      console.error("Ethereum object doesn't exist!");
    }
  };

  if (!isWalletConnected) {
    // Show only the connect wallet button if not connected
    return (
      <div className="connect-wallet-center ">
        <button className="connect-wallet-button" onClick={handleWalletConnect}>
          Connect Wallet
        </button>
      </div>
    );
  }

  if (typeof window.ethereum !== "undefined" && !isCorrectNetwork) {
    // MetaMask or a similar extension is installed, but not connected to the correct network
    return (
      <div className="network-warning">
        <p>Please connect to the BSC Network.</p>
        <button onClick={checkAndSwitchNetwork}>Switch Network</button>
      </div>
    );
  }

  //-----------------------------------------------------------------------------------------------------------//
  //New Code From here

  const claimAirdrop = async () => {
    setErrorMessage(""); // Clear the error message at the start of the function
    if (!signer || !isWalletConnected) {
      console.error("Wallet not connected!");
      return;
    }

    setIsClaiming(true);
    setClaimSuccess(false);

    try {
      const contractWithSigner = contract.connect(signer);
      const gasLimit = ethers.BigNumber.from("10000000"); // Adjust this value based on your needs
      const tx = await contractWithSigner.claimAirdrop({
        value: ethers.utils.parseEther("0.0013"),
      }); // 0.1 BNB, adjust as necessary
      const receipt = await tx.wait();
      const airdropEvent = receipt.events?.find(
        (event) => event.event === "AirdropClaimed"
      );
      if (airdropEvent) {
        const airdropAmountInTokens = airdropEvent.args.amount; // or the correct path to the amount
        setAirdropAmount(ethers.utils.formatEther(airdropAmountInTokens));
      }
      setIsClaiming(false);
      setClaimSuccess(true);
      setTimeout(() => setClaimSuccess(false), 10000); // Hide the message after 10 seconds
      console.log("Airdrop claimed!");
      fetchAirdrops();
    } catch (error) {
      console.error("Error in blockchain call:", error);
      if (
        error.message.includes("execution reverted: Claim interval not reached")
      ) {
        setErrorMessage(
          "No puedes reclamar el Airdrop todavía. No se ha alcanzado el plazo de reclamación."
        );
      } else if (
        error.message.includes(
          "execution reverted: Insufficient required token balance"
        )
      ) {
        setErrorMessage(
          "La transacción falló: saldo de BUX token insuficiente."
        );
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
      setIsClaiming(false);
    }
  };

  const handleClearErrorMessage = () => {
    setErrorMessage("");
  };
  const toggleTableVisibility = () => {
    setShowTable(!showTable); // Toggle the state between true and false
  };

  function displayPartialAddress(fullAddress) {
    const firstPart = fullAddress.substring(0, 10);
    const lastPart = fullAddress.substring(fullAddress.length - 10);
    return `${firstPart}...${lastPart}`;
  }

  const generateReferralCode = async () => {
    if (!signer || !isWalletConnected) {
      console.error("Wallet not connected!");
      return;
    }

    try {
      const contractWithSigner = contract.connect(signer);
      const generatedCode = await contractWithSigner.generateReferralCode();
      setReferralCode(generatedCode);
    } catch (error) {
      console.error("Error generating referral code:", error);
    }
  };

  const showCopiedNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  };

  const copyReferralCode = () => {
    const textToCopy = myReferralCode;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    // Show the notification
    showCopiedNotification();
  };

  const handleButtonClick = () => {
    fetchMyReferralCode();
    setShowReferralCode(!showReferralCode);
  };

  const handleSetReferralCode = async () => {
    if (!signer || !isWalletConnected) {
      setReferralCodeError("Wallet not connected!");
      return;
    }

    try {
      setIsSettingReferralCode(true);
      const contractWithSigner = contract.connect(signer);
      const tx = await contractWithSigner.setReferralCode(userReferralCode);
      await tx.wait();

      console.log("Referral code set successfully!");
      setReferralCodeSuccessMessage(
        "Código de referidos establecido con éxito."
      );
      setIsSettingReferralCode(false);
      setReferralCodeError(""); // Clear any existing error message

      setTimeout(() => {
        setUserReferralCode("");
        setReferralCodeSuccessMessage(""); // Clear the success message after some time
      }, 5000);
    } catch (error) {
      // Handle errors
      if (
        error.message.includes(
          "execution reverted: Cannot use self-generated code"
        )
      ) {
        setReferralCodeError(
          "No se puede utilizar código autogenerado. Por favor ingresa un código diferente."
        );
      } else {
        setReferralCodeError(
          "Error al configurar el código de referencia. Inténtalo de nuevo."
        );
      }
      console.error("Transaction error:", error);
      setIsSettingReferralCode(false);
    }
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  const claimReferralRewards = async () => {
    if (!signer || !isWalletConnected) {
      setReferralClaimError("Wallet not connected!");
      return;
    }

    setIsClaiming(true);

    try {
      const contractWithSigner = contract.connect(signer);
      const requiredBNBAmount = ethers.utils.parseEther("0.002");
      const tx = await contractWithSigner.claimReferralRewards({
        value: requiredBNBAmount,
      });
      await tx.wait();
      console.log("Referral rewards claimed successfully!");
      setIsClaiming(false);
      fetchReferralRewards();
      setReferralClaimError(""); // Clear any existing error message
    } catch (error) {
      if (error.message.includes("Not enough referral rewards")) {
        setReferralClaimError(
          "No hay suficientes recompensas por Referidos para reclamar.."
        );
      } else {
        // Handle other errors
        setReferralClaimError(
          "An unexpected error occurred. Please try again."
        );
      }
      console.error("Error in claiming referral rewards:", error);
      setIsClaiming(false);
    }
  };

  return (
    <div>
      {/* Top Bar */}

      <div className="main-container-dapp">
        <div className="tab-buttons">
          <button
            onClick={() => switchTab("airdrops")}
            className={activeTab === "airdrops" ? "active" : ""}
          >
            Airdrops
          </button>
          <button
            onClick={() => switchTab("referrals")}
            className={activeTab === "referrals" ? "active" : ""}
          >
            Referidos
          </button>
          <button
            onClick={() => switchTab("Incrementar")}
            className={activeTab === "Incrementar" ? "active" : ""}
          >
            Incrementar Ganancias
          </button>
        </div>

        <div className="tab-content">
          {activeTab === "airdrops" && (
            //------------------------------------------------------------------------------------------------------------------------------------------
            // Airdrops tab content here
            <div>
              <div className="airdrop-container-dapp">
                <div className="max-amount-win-container">
                  <span className="max-amount-title">Gana hasta:</span>
                  <span className="max-amount-value">
                    5,000,000,000,000 BUX
                  </span>
                </div>
                <div className="total-airdropped-container">
                  <span className="label">Total Regalado:</span>
                  <span className="amount">{totalAmount} BUX</span>
                </div>
              </div>
              {claimSuccess && (
                <div className="congratulations-message-container">
                  <span className="label2">¡Felicidades! </span>
                  <span className="label2">Recibiste</span>
                  <span className="amount2">{airdropAmount} BUX!</span>
                </div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="countdown-container">{displayTimer()}</div>

              <div className="button-container-airdrop-toggle">
                <button
                  onClick={claimAirdrop}
                  disabled={isClaiming}
                  className="claim-airdrop-button-dapp"
                >
                  {isClaiming ? "Reclamando..." : "Reclamar Airdrop"}
                </button>
              </div>

              <div className="button-container-airdrop-toggle">
                <button
                  className="button-toggle"
                  onClick={toggleTableVisibility}
                >
                  {showTable ? "Esconder Airdrops" : "Ver Ultimos Airdrops"}
                </button>
              </div>

              <div>
              {showTable && (
    <div className="airdrops-table-container-dapp">
        <h1>Ultimos Airdrops</h1>
        <table className="airdrops-table-dapp">
            <thead>
                <tr>
                    <th>Wallet</th>
                    <th>Cantidad (BUX)</th>
                    <th>Valor Actual en USDT</th>
                    <th>Fecha</th>
                </tr>
            </thead>
            <tbody>
                {[...airdrops] // Create a copy to avoid mutating the original array
                .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp in descending order
                .map((airdrop, index) => {
                    const airdropValueUSDT = tokenPrice && !isNaN(parseFloat(airdrop.amount))
                        ? (parseFloat(airdrop.amount) * parseFloat(tokenPrice)).toFixed(2)
                        : "Loading...";
                    return (
                        <tr key={index}>
                            <td className="user-cell">{displayPartialAddress(airdrop.user)}</td>
                            <td>{airdrop.amount} BUX</td>
                            <td>{`$ ${airdropValueUSDT} USDT`}</td>
                            <td>{airdrop.timestamp.toString()}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
)}


</div>

            </div>
          )}

          {activeTab === "referrals" && (
            //------------------------------------------------------------------------------------------------------------------------------------------
            // Referrals tab content here
            <div className="referrals-main-container">
              <div className="airdrop-container-dapp">
                <div className="current-referral-rewards-container">
                  <span className="label">Recompensas por Referidos</span>
                  <span className="amount">{referralRewards} BUX</span>
                </div>
              </div>

              <div className="claim-referral-rewards-container">
                <button
                  onClick={claimReferralRewards}
                  disabled={isClaiming || referralRewards < 800000000000}
                  className="claim-referral-rewards-button"
                >
                  {isClaiming
                    ? "Reclamando..."
                    : referralRewards < 800000000000
                    ? "No se pueden reclamar recompensas en este momento - mínimo requerido: 800,000,000,000 tokens"
                    : "Reclamar Recompensas por Referidos"}
                </button>
              </div>

              {referralClaimError && (
                <div className="referral-claim-error-message">
                  {referralClaimError}
                </div>
              )}

              <div className="button-container-airdrop-toggle">
                <button
                  onClick={generateReferralCode}
                  className="generate-referral-button"
                >
                  Crear Código de Referidos
                </button>

                <button className="button-toggle" onClick={handleButtonClick}>
                  {showReferralCode
                    ? "Esconder Código de Referidos"
                    : "Ver Código de Referidos"}
                </button>

                {showReferralCode && (
                  <div className="referral-code-display-container">
                    <div className="referral-code-display">
                      Su código de Referidos: {myReferralCode}
                    </div>
                    <button onClick={copyReferralCode}>Copiar Código</button>{" "}
                    {/* Add a Copy button */}
                  </div>
                )}

                {showNotification && (
                  <div className="notification">
                    ¡Código de referencia copiado al portapapeles!
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === "Incrementar" && (
            //------------------------------------------------------------------------------------------------------------------------------------------
            // Incrementar tab content here
            <div className="referrals-main-container">
              <div className="button-container-airdrop-toggle">
                <div className="referral-code-input-container">
                  <input
                    type="text"
                    placeholder="Ingrese el código de Referidos y aumente sus ganacias un 15%."
                    value={userReferralCode}
                    onChange={(e) => {
                      setUserReferralCode(e.target.value);
                      setReferralCodeError(""); // Clear the error message when user starts typing
                    }}
                  />
                  <button
                    className="set-referral-code-button"
                    onClick={handleSetReferralCode}
                    disabled={isSettingReferralCode}
                  >
                    {isSettingReferralCode
                      ? "Agregando..."
                      : "Agregar codigo de Referidos"}
                  </button>
                </div>

                {referralCodeError && (
                  <div className="error-message">{referralCodeError}</div>
                )}
                {referralCodeSuccessMessage && (
                  <div className="error-message">
                    {referralCodeSuccessMessage}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Other content can be added here */}
      </div>
    </div>
  );
}
